html {
	font-size: 18px;
}

body {
	overflow-x: hidden;
}

a,
a:hover {
	text-decoration: none;
}

svg {
	vertical-align: middle;
	shape-rendering: inherit;
}

label {
	margin-bottom: 0.5rem;
}
